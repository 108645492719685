.design-examples-wrapper {
    width: 100%;
}

.design-example-wrapper {
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: perspective(100rem);
    transform: perspective(100rem);
    cursor: pointer;

    @media only screen and (max-width: 1100px) {
        margin-bottom: 10px;
    }

    >.design-example {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

        --rX: 0;
        --rY: 0;
        --bX: 50%;
        --bY: 80%;
        position: relative;
        -webkit-transform: rotateX(calc(var(--rX) * 1deg)) rotateY(calc(var(--rY) * 1deg));
        transform: rotateX(calc(var(--rX) * 1deg)) rotateY(calc(var(--rY) * 1deg));
        transition: -webkit-transform .6s 1s;
    }

    &::before,
    &::after {
        content: "";
        width: 2rem;
        height: 2rem;
        border: 1px solid #fff;
        position: absolute;
        z-index: 2;
        opacity: .3;
        transition: .3s;
    }

    &::before {
        top: 2rem;
        right: 2rem;
        border-bottom-width: 0;
        border-left-width: 0;

        @media only screen and (max-width: 1100px) {
            display: none;
        }

    }

    &::after {
        bottom: 2rem;
        left: 2rem;
        border-top-width: 0;
        border-right-width: 0;

        @media only screen and (max-width: 1100px) {
            display: none;
        }

    }

    &--active {
        transition: none;
    }

    &.hide-on-mobile {
        @media only screen and (max-width: 1100px) {
            display: none;
        }

    }
}