@import "../../routes/application/variables.scss";

.ui-ux-examples-wrapper {
    width: 100%;

    >.row {
        width: 100%;
        clear: both;
        margin-top: $margins;
        display: table;

        @media only screen and (max-width: 1100px) {
            margin: 0;
        }
    }

    .row-1 {
        width: 100%;
        clear: both;
        display: table;

        >.row-1-left {
            width: 60%;
            float: left;
            margin-right: $margins;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }

            >.row-1-left-top {
                width: 100%;
                margin-bottom: $margins;

                @media only screen and (max-width: 1100px) {
                    width: 100%;
                    margin: 0;
                }
            }

            >.row-1-left-bottom {
                width: 100%;

                >.left {
                    float: left;
                    width: calc(44% - 20px);
                    margin-right: $margins;

                    @media only screen and (max-width: 1100px) {
                        width: 100%;
                        margin: 0;
                    }
                }

                >.middle {
                    float: left;
                    margin-right: 10px;
                    width: calc(28% - 5px);

                    @media only screen and (max-width: 1100px) {
                        width: 100%;
                        margin: 0;
                    }
                }

                >.right {
                    float: left;
                    width: calc(28% - 5px);

                    @media only screen and (max-width: 1100px) {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }

        >.row-1-right {
            float: left;
            width: calc(40% - 20px);

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

    }

    >.row-2 {
        width: 100%;
        clear: both;
        margin-top: $margins;
        display: table;

        @media only screen and (max-width: 1100px) {
            width: 100%;
            margin: 0;
        }

        >.row-2-left {
            width: calc(37% - 20px);
            margin-right: $margins;
            float: left;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

        >.row-2-right {
            width: 63%;
            float: left;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }
    }

    >.row-3 {
        >.row-3-left {
            width: calc(66% - 20px);
            float: left;
            margin-right: $margins;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

        >.row-3-right {
            width: 34%;
            float: left;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }
    }

    >.row-5 {
        >.row-5-left {
            width: calc(36.5% - 20px);
            float: left;
            margin-right: $margins;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

        >.row-5-right {
            width: 63.5%;
            float: left;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

    }

    >.row-6 {
        >.row-6-left-column {
            width: calc(56.2% - 20px);
            float: left;
            margin-right: $margins;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }

        >.row-6-right-column {
            width: 43.8%;
            float: left;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                margin: 0;
            }
        }
    }

}

.row-margin-bottom {
    margin-bottom: $margins;

    @media only screen and (max-width: 1100px) {
        margin: 0;
    }
}

.ui-ux-example-wrapper {
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: perspective(100rem);
    transform: perspective(100rem);
    cursor: pointer;

    @media only screen and (max-width: 1100px) {
        margin-bottom: 10px;
    }


    >.ui-ux-example {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

        --rX: 0;
        --rY: 0;
        --bX: 50%;
        --bY: 80%;
        position: relative;
        -webkit-transform: rotateX(calc(var(--rX) * 1deg)) rotateY(calc(var(--rY) * 1deg));
        transform: rotateX(calc(var(--rX) * 1deg)) rotateY(calc(var(--rY) * 1deg));
        transition: -webkit-transform .6s 1s;
    }

    &::before,
    &::after {
        content: "";
        width: 2rem;
        height: 2rem;
        border: 1px solid #fff;
        position: absolute;
        z-index: 2;
        opacity: .3;
        transition: .3s;
    }

    &::before {
        top: 2rem;
        right: 2rem;
        border-bottom-width: 0;
        border-left-width: 0;

        @media only screen and (max-width: 1100px) {
            display: none;
        }
    }

    &::after {
        bottom: 2rem;
        left: 2rem;
        border-top-width: 0;
        border-right-width: 0;

        @media only screen and (max-width: 1100px) {
            display: none;
        }
    }

    &--active {
        transition: none;
    }

    &.hide-on-mobile {
        @media only screen and (max-width: 1100px) {
            display: none;
        }

    }
}