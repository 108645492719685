@import "./variables.scss";

body {
    background-color: $background-dark;
    font-family: $font-primary;
    font-weight: 500;
}

.application-wrapper {
    width: calc(100% - 100px);
    margin: auto;
    // display: table;

    @media only screen and (max-width: 1100px) {
        width: calc(100% - 20px);
    }
}