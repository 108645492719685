#code-examples-wrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    display: table;
    padding: 50px 0;
}

.code-example-wrapper {
    width: calc(100%);
    clear: both;
    display: table;
    padding-bottom: 50px;

    >.code-example-heading {
        color: white;
        font-size: 30px;
        padding-bottom: 15px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        transition: all 0.2s;

        @media only screen and (max-width: 1100px) {
            width: 100%;
            text-align: center;
        }

        &:hover {
            color: #C656B2;
        }
    }

    >.code-example-image {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    >.code-example-about-wrapper {
        width: calc(50% - 20px);
        padding-top: 15px;
        padding-right: 20px;
        float: left;

        @media only screen and (max-width: 1100px) {
            width: 100%;
        }

        >.code-example-about-heading {
            color: white;
            font-size: 25px;

            @media only screen and (max-width: 1100px) {
                font-size: 20px;
                text-align: center;
            }
        }

        >.code-example-about-text {
            padding-top: 10px;
            width: 100%;
            font-size: 15px;
            color: white;
        }
    }

    >.code-example-technologies-wrapper {
        width: calc(50%);
        padding-top: 15px;
        float: left;

        @media only screen and (max-width: 1100px) {
            width: 100%;
        }

        >.code-example-technologies-heading {
            color: white;
            font-size: 25px;

            @media only screen and (max-width: 1100px) {
                font-size: 20px;
                text-align: center;
            }
        }

        >.code-example-technologies-text {
            padding-top: 10px;

            >.technology-tag {
                padding: 5px 10px;
                float: left;
                margin-right: 5px;
                margin-bottom: 5px;
                background-color: #ddd;
                color: #444;
                border-radius: 3px;
                font-size: 12px;
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
            }
        }
    }
}