@import "../../routes/application/variables.scss";

.header-wrapper {
    padding: 20px 0;
    display: table;
    width: 100%;

    >.header-photo-wrapper {
        width: 150px;
        float: left;

        @media only screen and (max-width: 1100px) {
            width: 75px
        }

        >.header-photo {
            width: 100%;
        }
    }

    >.header-info-wrapper {
        margin-top: 55px;
        margin-left: 40px;
        float: left;

        @media only screen and (max-width: 1100px) {
            margin-left: 20px;
            margin-top: 20px;
            width: calc(100% - 95px)
        }

        >.header-name {
            width: 100%;
            font-size: 40px;
            color: $light-gray;
            font-weight: 600;
            text-align: right;
            line-height: 1em;

            @media only screen and (max-width: 1100px) {
                font-size: 30px;
            }
        }

        >.header-description {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
            font-size: 20px;
            line-height: 1em;
            color: $dark-gray;
            font-weight: 500;
        }
    }

    >.header-contact {
        float: right;
        color: white;
        text-decoration: none;
        font-weight: 400;
        margin-top: 80px;
        display: block;
        font-size: 18px;

        @media only screen and (max-width: 1100px) {
            margin-top: 0px;
            width: 100%;
            clear: both;
            text-align: right;
            font-size: 12px;
        }
    }
}